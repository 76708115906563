<template>
  <v-card class="mt-4">
    <v-card-title>ALERTAS DE FUGA</v-card-title>
    <v-card-text>
      <!-- datatable toolbar with search input-->
      <v-toolbar flat color="white">
        <v-toolbar-title>Alertas</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar por cedula"
          single-line
          hide-details
        ></v-text-field>
        <!--button to search by cedula-->
        <v-btn class="ml-2" color="primary" rounded @click="onPaginate()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
          :headers="headers"
          :items="results.results"
          :items-per-page="15"
          item-key="id"
          :server-items-length="results?.count"
          v-on:update:options="onPaginate"
          :footer-props="{
            'items-per-page-options': [5,10,15]
          }"
      >
        <!-- render evidences -->
        <template v-slot:item.evidences="{ item }">
          <v-row>
            <v-col cols="12" v-for="evidence in item.evidences" :key="evidence.id">
              <evidence-item :evidence-item="evidence"></evidence-item>
            </v-col>
          </v-row>
        </template>

      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {listNews} from "@/services/SelfJustice";
import EvidenceItem from "@/components/commonComponents/EvidenceItem";
import {isEmpty} from "lodash";
export default {
  name: "AlertsList",
  components : {EvidenceItem},
  data(){
    return {
      headers : [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Fecha",
          value: "date",
        },
        {
          text: "Descripción",
          value: "description",
        },
        {
          text : 'Identificación',
          value: "review_request_alert.involved_identification",
        },
        {
          text: "Desarmonizante",
          value: "review_request_alert.involved_name",
        },
        {
          text: "Evidencia",
          value: "evidences",
        }
      ],
      alerts  : [],
      results : {},
      search  : '',
    }
  },
  methods : {
    onPaginate: async function (
        pagination = { sortBy: [], sortDesc: [], itemsPerPage: 15, page: 1 }
    ) {
      const { sortBy, sortDesc, itemsPerPage, page } = pagination;
      this.results = (await listNews({
        page,
        itemsPerPage,
        newsType: 'ALERT',
        ...(!isEmpty(this.search) && {search: this.search}),
        ...(sortBy[0] && {[`${sortBy[0]}`]: sortDesc[0]})
      }))?.data;
    },
  }
}
</script>

<style scoped>

</style>
